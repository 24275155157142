
import FileUpload from 'primevue/fileupload'
import { useToast } from 'primevue/usetoast'
import { defineComponent, ref } from 'vue'
import { ToastLife } from '@/models/components/toast/enums'
import { uploadSvg } from '@/api/www.api'
import useClipboard from 'vue-clipboard3'
import { SvgProcessingOptions } from '@/models/www/SvgUploader'

export default defineComponent({
  name: 'SvgUploadHome',
  components: {
    FileUpload
  },
  setup () {
    const { toClipboard } = useClipboard()
    const toast = useToast()
    const loading = ref<boolean>(false)
    const uploadedSvgUrl = ref<string>('')
    const processingOptions = ref<
      { label: string; key: string; value: boolean }[]
    >([
      {
        label: 'No procesar tamaño',
        key: 'noSizeProcessing',
        value: false
      },
      {
        label: 'No procesar color',
        key: 'noColorProcessing',
        value: false
      },
      {
        label: 'No enmascarar IDs',
        key: 'noIdMasking',
        value: false
      },
      {
        label: 'No subir imagenes embebidas',
        key: 'noImageUpload',
        value: false
      },
      {
        label: 'No minificar',
        key: 'noMinification',
        value: false
      }
    ])

    const onUpload: (event: { files: File[] }) => Promise<void> = async (
      event
    ) => {
      loading.value = true
      try {
        const optionsMapped = processingOptions.value.reduce(
          (acc: { [key: string]: boolean }, option) => {
            acc[option.key] = option.value || false
            return acc
          },
          {}
        )

        const response = await uploadSvg(event.files[0], optionsMapped as unknown as SvgProcessingOptions)
        uploadedSvgUrl.value = response.url
        toast.add({
          severity: 'success',
          summary: 'SVG cargado',
          detail: 'El SVG ha sido cargado correctamente',
          life: ToastLife.Default
        })
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'Error al subir el archivo',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }

      loading.value = false
      // await copyUrlToClipboard()
    }

    const copyUrlToClipboard = async () => {
      if (!uploadedSvgUrl.value) {
        toast.add({
          severity: 'error',
          summary: 'Error al copiar el URL',
          detail: 'No se ha cargado ningún SVG',
          life: ToastLife.Default
        })
        return
      }
      try {
        await toClipboard(uploadedSvgUrl.value)
        toast.add({
          severity: 'success',
          summary: 'URL copiada',
          detail: 'La URL del SVG ha sido copiado al portapapeles',
          life: ToastLife.Default
        })
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'Error al copiar la URL',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
    }

    const setLoadingValue = (value: boolean) => {
      loading.value = value
    }

    return {
      loading,
      onUpload,
      uploadedSvgUrl,
      copyUrlToClipboard,
      setLoadingValue,
      processingOptions
    }
  }
})
